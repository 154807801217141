body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  height: 10rem;
  margin-top: 1rem;
}

#form {
  width: 25rem;
  margin-top: 2rem;
}

#form > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;
}

#form > div > label {
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  color: #7f8fa4;
  margin-bottom: 0.5rem;
}

#form > div > input,
#form > div > select,
#form > div > textarea {
  height: 2.25rem;
  border-radius: 4px;
  border: solid 1px #dfe3e9;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  line-height: 1.36;
  color: #354052;
  padding-left: 1rem;
}

#form > div > textarea {
  padding: 10px 1rem;
}

#form > #form-submit {
  align-items: flex-end;
}

#form > #form-submit > div > button {
  width: 163px;
  height: 36px;
  border-radius: 4px;
  border: solid 1px #67ba06;
  background-image: linear-gradient(to bottom, #72d400 2%, #67bf01 98%);
  font-family: SourceSansPro;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.36;
  color: #ffffff;
  cursor: pointer;
}

#form > #form-submit > div > button:active {
  transform: matrix(0.98, 0, 0, 0.98, 0, 0);
}

#form > #form-submit > div > button:focus {
  outline: none;
}

#form .error {
  color: red;
  position: absolute;
  display: block;
  font-size: 14px;
  top: 67px;
  left: 4px;
}

#message {
  display: none;
  margin-top: 2rem;
  font-family: "Source Sans Pro";
}

@media only screen and (max-width: 450px) {
  #form {
    width: 19rem;
  }
  #form > #form-submit > div > button {
    width: 19rem;
  }
}

.red {
  color: red;
}

#checkbox {
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  color: #7f8fa4;
  margin-bottom: 0.5rem;
}
